import React from 'react';

import Navbar from '../components/Navbar/Navbar';
import ProjectsPage from '../components/Projects/ProjectsPage';
import Footer from '../components/Footer/Footer';

export default function ProjectsPagePage() {
  return (
    <div>
      <Navbar />
      <br/>
      <ProjectsPage />
      <Footer />
    </div>
  );
}
